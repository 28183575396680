
import { defineComponent, ref, reactive, nextTick, onMounted } from "vue";
import { useRouter } from "vue-router";
import { UndefStr } from "../types";
import { findAllDealerFaq } from "@/API/faq";
export default defineComponent({
  setup() {
    const router = useRouter();
    const spaceSize = 6;
    const question = ref<UndefStr>(undefined);
    const category = ref<UndefStr>(undefined);
    const categoryList = ref<any>([
      { type: "Technical Support", id: 1 },
      { type: "Business Treatment", id: 2 },
    ]);
    //重置
    const reset = () => {
      question.value = undefined;
      category.value = undefined;
    };
    //分页组件
    const pagination = reactive({
      total: 0,
      pageIndex: 0,
      pageSize: 10,
    });
    //查询
    const search = (pageIndex: number, pageSize: number) => {
      const params = {
        question: question.value,
        category: category.value,
        pageIndex,
        pageSize,
      };
      findAllDealerFaq(params).then((res: any) => {
        dataSource.value = res.content;
        pagination.total = res.totalElements;
      });
    };

    const handleSearch = () => {
      pagination.pageIndex = 1;
      search(1, pagination.pageSize);
    };
    search(1, pagination.pageSize);
    const columns = [
      {
        title: "分类",
        dataIndex: "categoryEn",
        align: "center",
        width: 150,
        key: "1",
      },
      {
        title: "标题",
        dataIndex: "question",
        align: "center",
        width: 200,
        key: "2",
        ellipsis: true,
      },
      {
        title: "发布时间",
        dataIndex: "publishDate",
        align: "center",
        width: 150,
        key: "3",
      },
      {
        title: "操作",
        dataIndex: "action",
        align: "center",
        width: 200,
        key: "4",
        slots: { customRender: "action" },
      },
    ];
    //预览
    const view = (faqId: string) => {
      router.push({
        name: "FAQ Dealer View",
        params: {
          faqId,
        },
      });
    };

    const dataSource = ref<any>([]);
    const tableWidth = columns.reduce((totalWidth: number, col: any) => {
      return totalWidth + col.width;
    }, 0);
    const colHeight = ref(500);
    const calculateHeight = () => {
      const table = document.getElementsByClassName("dealerManagementTable")[0];
      const clinetHeight = document.body.clientHeight;
      const top = table.getBoundingClientRect().top;
      colHeight.value = clinetHeight - top - 130;
    };
    nextTick(() => {
      calculateHeight();
    });
    onMounted(() => {
      window.addEventListener("resize", calculateHeight);
    });
    //分页逻辑
    const pageChange = (page: number, pageSize: number) => {
      pagination.pageIndex = page > 0 ? page - 1 : 0;
      pagination.pageSize = pageSize;
      search(pagination.pageIndex, pagination.pageSize);
    };
    const sizeChange = (page: number, pageSize: number) => {
      pagination.pageIndex = page > 0 ? page - 1 : 0;
      pagination.pageSize = pageSize;
      search(pagination.pageIndex, pagination.pageSize);
    };

    return {
      spaceSize,
      question,
      category,
      categoryList,
      reset,
      search,
      view,
      columns,
      dataSource,
      tableWidth,
      colHeight,
      pagination,
      pageChange,
      sizeChange,
      handleSearch,
    };
  },
});
